import Head from 'next/head';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { RequestContext } from '../../../helpers/requestContext';
import nextI18NextConfig from '../../../next-i18next.config';

const AlternateLinks = () => {
  const requestContextData = useContext(RequestContext);
  const { asPath } = useRouter();

  const absoluteBaseUrl = `https://${requestContextData?.hostname}`;

  return (
    <Head key="alternate-links">
      {nextI18NextConfig.i18n.locales.map((locale) => (
        <link
          key={`alternate-${locale}`}
          rel="alternate"
          hrefLang={locale}
          href={`${absoluteBaseUrl}${locale !== 'cs' ? `/${locale}` : ''}${asPath}`}
        />
      ))}
    </Head>
  );
};

export default AlternateLinks;
